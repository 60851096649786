import React, { useEffect, useState } from "react"
// import axios from "axios"

import SubjectsList from "../components/SubjectsList"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner"
import { useHttpClient } from "../../shared/hooks/http-hook"

const UserSubjects = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient()

  const [loadedSubjects, setLoadedSubjects] = useState()

  useEffect(() => {
    const fetchSubjects = async () => {
      console.log("fetchSubjects")
      try {
        const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/subjects")
        setLoadedSubjects(responseData.subjects)
      } catch (err) {}
    }
    fetchSubjects()
  }, [sendRequest])

  const sortedSubject = loadedSubjects && [...loadedSubjects].sort((a, b) => new Date(b.created) - new Date(a.created))

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && sortedSubject && <SubjectsList items={sortedSubject} />}
    </React.Fragment>
  )
}

export default UserSubjects

import React, { useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Input from "../../shared/components/FormElements/Input"
import Button from "../../shared/components/FormElements/Button"
import Card from "../../shared/components/UIElements/Card"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import { VALIDATOR_MINLENGTH } from "../../shared/util/validators"
import { VALIDATOR_INTEGER } from "../../shared/util/validators"
import { useForm } from "../../shared/hooks/form-hook"
import { useHttpClient } from "../../shared/hooks/http-hook"
import { AuthContext } from "../../shared/context/auth-context"
import "./PlaceForm.css"

const UpdatePic = () => {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [loadedPic, setLoadedPic] = useState()
  const picId = useParams().picId
  console.log("picId in UpdatePic: ", picId)
  const [formState, inputHandler, setFormData] = useForm(
    {
      copyright: {
        value: "",
        isValid: false,
      },
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      ord: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    const fetchPic = async () => {
      try {
        console.log("responseData: ")
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/pics/${picId}`)
        setLoadedPic(responseData.pic)
        setFormData(
          {
            copyright: {
              value: responseData.pic.copyright,
              isValid: true,
            },
            title: {
              value: responseData.pic.title,
              isValid: true,
            },
            description: {
              value: responseData.pic.description,
              isValid: true,
            },
            ord: {
              value: responseData.pic.ord,
              isValid: true,
            },
          },
          true
        )
      } catch (err) {}
    }
    fetchPic()
  }, [sendRequest, picId, setFormData])

  const picUpdateSubmitHandler = async (event) => {
    event.preventDefault()
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/pics/${picId}`,
        "PATCH",
        JSON.stringify({
          copyright: formState.inputs.copyright.value,
          title: formState.inputs.title.value,
          description: formState.inputs.description.value,
          ord: formState.inputs.ord.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      )
      navigate(-1) // Navigiert zur vorherigen Seite
    } catch (err) {}
  }

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    )
  }

  if (!loadedPic && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find pic!</h2>
        </Card>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && loadedPic && (
        <form className="place-form" onSubmit={picUpdateSubmitHandler}>
          <Input
            id="copyright"
            element="input"
            type="text"
            label="Copyright"
            validators={[VALIDATOR_MINLENGTH(2)]}
            errorText="Please enter a valid Copyright (at least 2 characters)."
            onInput={inputHandler}
            initialValue={loadedPic.copyright}
            initialValid={true}
          />
          <Input id="title" element="input" type="text" label="Title" validators={[VALIDATOR_MINLENGTH(2)]} errorText="Please enter a valid title (at least 2 characters)." onInput={inputHandler} initialValue={loadedPic.title} initialValid={true} />
          <Input
            id="description"
            element="textarea"
            label="Description"
            validators={[VALIDATOR_MINLENGTH(2)]}
            errorText="Please enter a valid description (at least 2 characters)."
            onInput={inputHandler}
            initialValue={loadedPic.description}
            initialValid={true}
          />
          <Input id="ord" element="input" type="number" label="Ord" validators={[VALIDATOR_INTEGER]} errorText="Please enter a valid number." onInput={inputHandler} initialValue={loadedPic.ord} initialValid={true} />
          <Button type="submit" disabled={!formState.isValid}>
            SAVE DATES OF PIC
          </Button>
        </form>
      )}
    </React.Fragment>
  )
}

export default UpdatePic

import React, { useState } from "react"
import { Link } from "react-router-dom"

import Avatar from "../../shared/components/UIElements/Avatar"
import Card from "../../shared/components/UIElements/Card"
import "./SubjectItem.css"

const SubjectItem = (props) => {
  const [showFullDescription, setShowFullDescription] = useState(false)

  const descriptionToShow = showFullDescription ? props.description : `${props.description.split(" ").slice(0, 3).join(" ")} ...`

  const toggleDescriptionHandler = () => {
    setShowFullDescription((prevState) => !prevState)
  }

  return (
    <li className="subject-item">
      <Card className="subject-item__content">
        <div className="subject-item__header">
          <Link to={`/${props.id}/subjectplaces?title=${props.title}&description=${props.description}&image=${props.image}&picscount=${props.picsCount}`}>
            <div className="subject-item__image">
              <Avatar image={`${process.env.REACT_APP_ASSET_URL}/${props.image}`} alt={props.title} />
            </div>
          </Link>
        </div>
        <div className="subject-item__info">
          <h2>{props.title}</h2>
          <h4 onClick={toggleDescriptionHandler}>{descriptionToShow}</h4>
          <h3>
            {props.placeCount} {props.placeCount === 1 ? "Place" : "Places"}
          </h3>
        </div>
      </Card>
    </li>
  )
}

export default SubjectItem

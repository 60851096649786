import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import Input from "../../shared/components/FormElements/Input"
import Button from "../../shared/components/FormElements/Button"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner"
import ImageUpload from "../../shared/components/FormElements/ImageUpload"
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from "../../shared/util/validators"
import { useForm } from "../../shared/hooks/form-hook"
import { useHttpClient } from "../../shared/hooks/http-hook"
import { AuthContext } from "../../shared/context/auth-context"
import "./AddSubject.css"

const AddSubject = () => {
  const auth = useContext(AuthContext)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  )

  const navigate = useNavigate()

  const newsubjectSubmitHandler = async (event) => {
    event.preventDefault()
    console.log("newsubjectSubmitHandler1")
    console.log("newsubjectSubmitHandler Bearer", auth.token)
    try {
      const formData = new FormData()
      formData.append("title", formState.inputs.title.value)
      formData.append("image", formState.inputs.image.value)
      formData.append("description", formState.inputs.description.value)

      await sendRequest(process.env.REACT_APP_BACKEND_URL + "/subjects", "POST", formData, {
        Authorization: "Bearer " + auth.token,
      })
      navigate(-1) // Navigiert zur vorherigen Seite
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="place-form" onSubmit={newsubjectSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <Input element="input" id="title" type="text" label="Title" validators={[VALIDATOR_REQUIRE()]} errorText="Please enter a title." onInput={inputHandler} />
        <ImageUpload center id="image" onInput={inputHandler} errorText="Please provide an image." />
        <Input id="description" element="textarea" label="Description" validators={[VALIDATOR_MINLENGTH(5)]} errorText="Please enter a valid description (at least 5 characters)." onInput={inputHandler} />
        <Button type="submit" disabled={!formState.isValid}>
          SUBMIT
        </Button>
      </form>
    </React.Fragment>
  )
}

export default AddSubject

import { useState, useCallback, useEffect } from "react"

let logoutTimer

export const useAuth = () => {
  const [token, setToken] = useState(false)
  const [tokenExpirationDate, setTokenExpirationDate] = useState()
  const [userId, setUserId] = useState(false)
  const [email, setEmail] = useState(false)
  const [userName, setUserName] = useState(false)
  const [role, setRole] = useState(false)

  const login = useCallback((uid, token, email, userName, role, expirationDate) => {
    setToken(token)
    setUserId(uid)
    setEmail(email)
    setUserName(userName)
    setRole(role)
    // const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 2000)
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3)
    setTokenExpirationDate(tokenExpirationDate)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        email: email,
        userName: userName,
        role: role,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    )
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    setTokenExpirationDate(null)
    setUserId(null)
    setEmail(null)
    setUserName(null)
    setRole(null)
    localStorage.removeItem("userData")
  }, [])

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
      logoutTimer = setTimeout(logout, remainingTime)
    } else {
      clearTimeout(logoutTimer)
    }
  }, [token, logout, tokenExpirationDate])

  // login = useCallback((uid, token, email, userName, role, expirationDate)
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"))
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(storedData.userId, storedData.token, storedData.email, storedData.userName, storedData.role, new Date(storedData.expiration))
    }
  }, [login])

  return { token, login, logout, userId, email, userName, role }
}

import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import Startseite from "./user/pages/Startseite"
import AddPlace from "./places/pages/AddPlace"
import AddSubject from "./subjects/pages/AddSubject"
import Users from "./user/pages/Users"
import UserSubjects from "./subjects/pages/UserSubjects"
import EditSubject from "./subjects/pages/EditSubject"
import UserPlaces from "./places/pages/UserPlaces"
import SubjectPlaces from "./places/pages/SubjectPlaces"
import UpdatePlace from "./places/pages/UpdatePlace"
import Auth from "./user/pages/Auth"
import MainNavigation from "./shared/components/Navigation/MainNavigation"
import MyGallery from "./pics/pages/MyGallery"
import EcksGallery from "./pics/pages/EcksGallery"
import ListPicItemsWithText from "./pics/pages/ListPicItemsWithText"
import AddPic from "./pics/pages/AddPic"
import AddVideo from "./pics/pages/FileUploadVideo"
import UpdatePic from "./pics/pages/UpdatePic"
import { AuthContext } from "./shared/context/auth-context"
import { useAuth } from "./shared/hooks/auth-hook"

const App = () => {
  const { token, login, logout, userId, email, userName, role } = useAuth()

  let loginTitel = JSON.stringify(userName)
  if (loginTitel === "false" || loginTitel === "null" || loginTitel === "") {
    loginTitel = "Not authenticated"
  }

  let routes

  if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<UserSubjects />} />
        <Route path="/users" element={<Users />} />
        <Route path="/:userId/places" element={<UserPlaces />} />
        <Route path="/:subjectId/subjectplaces" element={<SubjectPlaces />} />
        <Route path="/places/new" element={<AddPlace />} />
        <Route path="/:subjectId/places/new" element={<AddPlace />} />
        <Route path="/subjects" element={<UserSubjects />} />
        <Route path="/subjects/newsubject" element={<AddSubject />} />
        <Route path="/editSubject/:subjectId" element={<EditSubject />} />

        <Route path="/places/:placeId/:subjectId" element={<UpdatePlace />} />

        <Route path="/mygallery/:id" element={<EcksGallery />} />
        <Route path="/listpics/:id" element={<ListPicItemsWithText />} />

        <Route path="/:id/addPic/:source" element={<AddPic />} />
        <Route path="/:id/addVideo/:source" element={<AddVideo />} />
        <Route path="/updatePic/:picId" element={<UpdatePic />} />

        <Route path="/auth" element={<UserSubjects />} />
      </Routes>
    )
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Startseite />} />
        <Route path="/subjects" element={<UserSubjects />} />
        <Route path="/:userId/places" element={<UserPlaces />} />
        <Route path="/:subjectId/places/new" element={<AddPlace />} />
        <Route path="/auth" element={<Auth />} />
      </Routes>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userName: userName,
        role: role,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <MainNavigation LoggedinName={loginTitel} />
        <main>{routes}</main>
      </Router>
    </AuthContext.Provider>
  )
}

export default App

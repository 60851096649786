import React, { useState, useContext } from "react"

import Card from "../../shared/components/UIElements/Card"
import Button from "../../shared/components/FormElements/Button"
import Modal from "../../shared/components/UIElements/Modal"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import { useHttpClient } from "../../shared/hooks/http-hook"
import "./PicItem.css"
import { AuthContext } from "../../shared/context/auth-context"

const PicItem = (props) => {
  // console.log("props: ", props)
  const auth = useContext(AuthContext)
  const authAdmin = useContext(AuthContext)
  const isAdmin = authAdmin.userName === "Eckhard Hagemeier"
  const picId = props.picid //isfromasubject or isfromaplace
  const image = props.image
  const title = props.title
  const description = props.description
  const ord = props.ord
  const belongstosource = props.belongstosource
  const belongstoid = props.belongstoid
  const { error, sendRequest, clearError } = useHttpClient()

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [showImageModal, setShowImageModal] = useState(false)

  const [showFullDescription, setShowFullDescription] = useState(false)
  const descriptionWords = description.split(" ")
  const truncatedDescription = showFullDescription ? description : descriptionWords.slice(0, 16).join(" ")

  const toggleDescriptionHandler = () => {
    setShowFullDescription(!showFullDescription)
  }

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true)
  }
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false)
  }

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false)
    try {
      // router.get("/isfromasubject/:source/:id/:picfilename", picsControllers.deletePic)
      // console.log("deletePic route: ", `${process.env.REACT_APP_BACKEND_URL}/pics/${source}/${id}/${filename}`)
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/pics/${belongstosource}/${belongstoid}/${picId}`, "DELETE", null, {
        Authorization: "Bearer " + auth.token,
      })
      props.onDelete(image)
    } catch (err) {}
  }

  const openImageModal = () => {
    setShowImageModal(true)
  }

  const closeImageModal = () => {
    setShowImageModal(false)
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {/* Modal for image display */}
      <Modal show={showImageModal} onCancel={closeImageModal} contentClass="pic-item__image-modal-content">
        <div className="pic-item__full-screen-image-container" onClick={closeImageModal}>
          <img src={`${process.env.REACT_APP_ASSET_URL}/${image}`} alt={image} className="pic-item__full-screen-image" />
        </div>
      </Modal>

      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Are you sure?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              DELETE
            </Button>
          </React.Fragment>
        }
      >
        <p>Do you want to proceed and delete this place? Please note that it can't be undone thereafter.</p>
      </Modal>
      <li className="pic-item">
        <Card className="pic-item__content">
          <div className="pic-item__image" onClick={openImageModal}>
            {props.video ? (
              // Render a video element if `video` is true
              <video width="320" height="240" controls preload="metadata">
                <source src={`${process.env.REACT_APP_ASSET_URL}/${image}`} type="video/mp4" />
              </video>
            ) : (
              // Render an image element if `video` is false
              <img src={`${process.env.REACT_APP_ASSET_URL}/${image}`} alt={image} className="pic-item__full-screen-image" />
            )}
          </div>
          <div>
            {title !== "Pic" && <div className="pic-item__title">{title}</div>}
            {title !== "Pic" && (
              <div className="pic-item__description">
                <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                {description.length > 100 && (
                  <button className="read-more-button" onClick={toggleDescriptionHandler}>
                    {showFullDescription ? "... weniger" : "... mehr"}
                  </button>
                )}
              </div>
            )}
          </div>

          <div>
            {isAdmin && (
              <Button danger onClick={showDeleteWarningHandler}>
                DELETE
              </Button>
            )}
            {isAdmin && <Button to={`/updatePic/${picId}`}>Ed</Button>}
            {isAdmin && <span> {ord}</span>}
          </div>
        </Card>
      </li>
    </React.Fragment>
  )
}
export default PicItem

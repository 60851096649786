import React, { useEffect, useState, useContext } from "react"
import { useParams, useLocation } from "react-router-dom"
import Card from "../../shared/components/UIElements/Card"
import Button from "../../shared/components/FormElements/Button"
import "./SubjectPlaces.css"

import { AuthContext } from "../../shared/context/auth-context"

import PlaceList from "../components/PlaceList"
// import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner"
import { useHttpClient } from "../../shared/hooks/http-hook"
import Avatar from "../../shared/components/UIElements/Avatar"

const SubjectPlaces = () => {
  const authAdmin = useContext(AuthContext)
  const isAdmin = authAdmin.userName === "Eckhard Hagemeier"

  const subjectId = useParams().subjectId
  const { search } = useLocation()
  const subjectTitle = new URLSearchParams(search).get("title")
  const subjectDescription = new URLSearchParams(search).get("description")
  const subjectImage = new URLSearchParams(search).get("image")
  const subjectPicsCount = new URLSearchParams(search).get("picscount")
  console.log("subjectPicsCount: ", subjectPicsCount)

  const [loadedPlaces, setLoadedPlaces] = useState()
  // const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const { isLoading, sendRequest } = useHttpClient()

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/places/subject/${subjectId}`)
        setLoadedPlaces(responseData.places)
      } catch (err) {}
    }
    fetchPlaces()
  }, [sendRequest, subjectId])

  // Sortieren nach dem 'created' Feld
  // setLoadedPlaces((prevPlaces) => [...prevPlaces].sort((a, b) => new Date(b.created) - new Date(a.created)))
  // Sortieren nach dem 'created' Feld
  const sortedPlaces = loadedPlaces && [...loadedPlaces].sort((a, b) => new Date(b.created) - new Date(a.created))

  const placeDeletedHandler = (deletedPlaceId) => {
    setLoadedPlaces((prevPlaces) => prevPlaces.filter((place) => place.id !== deletedPlaceId))
  }

  return (
    <React.Fragment>
      <div className="card-container">
        <Card className="card-content">
          <div className="card-avatar">
            <Avatar image={`${process.env.REACT_APP_ASSET_URL}/${subjectImage}`} alt={subjectTitle} />
          </div>
          <div className="card-texte">
            <h2>{subjectTitle}</h2>
            <h3>{subjectDescription}</h3>
          </div>
          {isAdmin && <Button to={`/editSubject/${subjectId}`}>Edit Subject</Button>}
          {isAdmin && <Button to={`/${subjectId}/places/new`}>Add Place</Button>}
          {!(subjectPicsCount <= 0) && <Button to={`/mygallery/${subjectId}`}>Gallery</Button>}
          {!(subjectPicsCount <= 0) && <Button to={`/listpics/${subjectId}`}>List Pics</Button>}
          {isAdmin && <Button to={`/${subjectId}/addPic/isfromasubject`}>Add Pic</Button>}
          {isAdmin && <Button to={`/${subjectId}/addVideo/isfromasubject`}>Add Video</Button>}
        </Card>
      </div>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedPlaces && <PlaceList items={sortedPlaces} onDeletePlace={placeDeletedHandler} />}
    </React.Fragment>
  )
}

export default SubjectPlaces

import React, { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Input from "../../shared/components/FormElements/Input"
import Button from "../../shared/components/FormElements/Button"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import ImageUpload from "../../shared/components/FormElements/ImageUpload"
import { VALIDATOR_MINLENGTH } from "../../shared/util/validators"
import { VALIDATOR_INTEGER } from "../../shared/util/validators"
import { useForm } from "../../shared/hooks/form-hook"
import { useHttpClient } from "../../shared/hooks/http-hook"
import { AuthContext } from "../../shared/context/auth-context"
import "./PlaceForm.css"

const AddPic = () => {
  const navigate = useNavigate()
  const id = useParams().id
  const source = useParams().source
  console.log("source: ", source)
  const [anotherImage, setAnotherImage] = useState(false)
  const auth = useContext(AuthContext)
  const { error, sendRequest, clearError } = useHttpClient()
  let [formState, inputHandler] = useForm(
    {
      image: {
        value: null,
        isValid: false,
      },
      copyright: {
        value: "",
        isValid: false,
      },
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      ord: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  const checkboxHandler = (event) => {
    setAnotherImage(event.target.checked)
  }

  const picSubmitHandler = async (event) => {
    event.preventDefault()
    try {
      const formData = new FormData()
      if (source === "isfromasubject") {
        formData.append("creatorsubject", id)
      } else {
        formData.append("creatorplace", id)
      }

      formData.append("copyright", formState.inputs.copyright.value)
      formData.append("title", formState.inputs.title.value)
      formData.append("description", formState.inputs.description.value)
      formData.append("ord", formState.inputs.ord.value)
      formData.append("image", formState.inputs.image.value)
      formData.append("belongstoid", id)
      formData.append("belongstosource", source)

      // Mit diesem Code kann ich überprüfen, welche Daten gesendet werden:
      const data = {}
      for (const [key, value] of formData.entries()) {
        data[key] = value
      }
      console.log("formdata: ", data)

      await sendRequest(process.env.REACT_APP_BACKEND_URL + "/pics", "POST", formData, {
        Authorization: "Bearer " + auth.token,
      })

      if (!anotherImage) {
        navigate(-1) // Navigiert zur vorherigen Seite
      } else {
        // hier sollte formState mit den Initialwerten überschrieben werden
      }
    } catch (err) {}
  }

  //  Die userID wird über die middelware im Backend in places-routes.js übermittelt:
  //  router.use(checkAuth)

  // <Input id="anotherImage" element="input" type="checkbox" label="Upload another image?" onInput={inputHandler} />

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="place-form" onSubmit={picSubmitHandler}>
        <ImageUpload id="image" onInput={inputHandler} errorText="Please provide an image." />
        <Input
          id="copyright"
          initialValue={"© EckiHag"}
          initialValid={true}
          element="input"
          type="text"
          label="Copyright"
          validators={[VALIDATOR_MINLENGTH(2)]}
          errorText="Please enter a valid description (at least 2 characters)."
          onInput={inputHandler}
        />
        <Input id="title" initialValue={"Pic"} initialValid={true} element="input" type="text" label="Title" validators={[VALIDATOR_MINLENGTH(2)]} errorText="Please enter a valid description (at least 2 characters)." onInput={inputHandler} />
        <Input
          id="description"
          initialValue={"No description"}
          initialValid={true}
          element="textarea"
          label="Description"
          validators={[VALIDATOR_MINLENGTH(2)]}
          errorText="Please enter a valid description (at least 2 characters)."
          onInput={inputHandler}
        />
        <Input id="ord" element="input" type="number" label="Ord" validators={[VALIDATOR_INTEGER]} errorText="Please enter a valid number." onInput={inputHandler} />
        <Button type="submit" disabled={!formState.isValid}>
          ADD PIC
        </Button>
        <label>
          <input type="checkbox" checked={anotherImage} onChange={checkboxHandler} />
          Add another image
        </label>
      </form>
    </React.Fragment>
  )
}

export default AddPic

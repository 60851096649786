import React, { useContext, useState, useEffect } from "react"
import { NavLink } from "react-router-dom"

import { AuthContext } from "../../context/auth-context"
import "./NavLinks.css"

const NavLinks = (props) => {
  const auth = useContext(AuthContext)
  const isAdmin = auth.userName === "Eckhard Hagemeier"

  const [isLoggedIn, setIsLoggedIn] = useState(auth.isLoggedIn)

  useEffect(() => {
    setIsLoggedIn(auth.isLoggedIn)
  }, [auth.isLoggedIn])

  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/subjects">ALL SUBJECTS</NavLink>
      </li>
      {/* {isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/places`}>MY PLACES</NavLink>
        </li>
      )} */}
      {/* {isLoggedIn && (
        <li>
          <NavLink to="/places/new">ADD PLACE</NavLink>
        </li>
      )} */}
      {isAdmin && (
        <li>
          <NavLink to="/subjects/newsubject">ADD SUBJECT</NavLink>
        </li>
      )}
      {isAdmin && (
        <li>
          <NavLink to="/users" exact>
            ALL USERS
          </NavLink>
        </li>
      )}
      {!isLoggedIn && (
        <li>
          <NavLink to="/auth">AUTHENTICATE</NavLink>
        </li>
      )}
      {isLoggedIn && (
        <li>
          <button onClick={auth.logout}>LOGOUT</button>
        </li>
      )}
    </ul>
  )
}

export default NavLinks

import React, { useState, useContext } from "react"

import Card from "../../shared/components/UIElements/Card"
import Button from "../../shared/components/FormElements/Button"
import Modal from "../../shared/components/UIElements/Modal"
import Map from "../../shared/components/UIElements/Map"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner"
import { AuthContext } from "../../shared/context/auth-context"
import { useHttpClient } from "../../shared/hooks/http-hook"
import "./PlaceItem.css"
import { Link } from "react-router-dom"

const PlaceItem = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const auth = useContext(AuthContext)
  const authAdmin = useContext(AuthContext)
  const isAdmin = authAdmin.userName === "Eckhard Hagemeier"

  const placeId = props.placeId
  const subjectId = props.subjectId

  const [showMap, setShowMap] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [showFullDescription, setShowFullDescription] = useState(false)
  const descriptionWords = props.description.split(" ")
  const truncatedDescription = showFullDescription ? props.description : descriptionWords.slice(0, 22).join(" ")

  const toggleDescriptionHandler = () => {
    setShowFullDescription(!showFullDescription)
  }

  const openMapHandler = () => setShowMap(true)
  const closeMapHandler = () => setShowMap(false)

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true)
  }
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false)
  }

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false)
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/places/${placeId}`, "DELETE", null, {
        Authorization: "Bearer " + auth.token,
      })
      props.onDelete(placeId)
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal show={showMap} onCancel={closeMapHandler} header={props.address} contentClass="place-item__modal-content" footerClass="place-item__modal-actions" footer={<Button onClick={closeMapHandler}>CLOSE</Button>}>
        <div className="map-container">
          <Map center={props.coordinates} zoom={16} />
        </div>
      </Modal>
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Are you sure?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              CANCEL
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              DELETE
            </Button>
          </React.Fragment>
        }
      >
        <p>Do you want to proceed and delete this place? Please note that it can't be undone thereafter.</p>
      </Modal>
      <li className="place-item">
        <Card className="place-item__content">
          {isLoading && <LoadingSpinner asOverlay />}
          <div className="place-item__image">
            {props.picsCount > 0 ? (
              <Link to={`/mygallery/${placeId}`}>
                <img src={`${process.env.REACT_APP_ASSET_URL}/${props.image}`} alt={props.title} />
              </Link>
            ) : (
              <img src={`${process.env.REACT_APP_ASSET_URL}/${props.image}`} alt={props.title} />
            )}
          </div>
          <div className="place-item__info">
            <h2>{props.title}</h2>
            <h3>{props.address}</h3>
            <p>
              {" "}
              <div className="pic-item__description">
                {truncatedDescription}
                {descriptionWords.length > 16 && (
                  <button className="read-more-button" onClick={toggleDescriptionHandler}>
                    {showFullDescription ? "... weniger" : "... mehr"}
                  </button>
                )}
              </div>
            </p>
          </div>
          <div className="place-item__actions">
            <Button inverse onClick={openMapHandler}>
              Map
            </Button>
            {!(props.picsCount <= 0) && <Button to={`/mygallery/${placeId}`}>Gallery</Button>}
            {!(props.picsCount <= 0) && <Button to={`/listpics/${placeId}`}>Stories</Button>}
            {auth.userId === props.creatorId && <Button to={`/places/${placeId}/${subjectId}`}>Ed</Button>}
            {auth.userId === props.creatorId && (
              <Button danger onClick={showDeleteWarningHandler}>
                Del
              </Button>
            )}
            {isAdmin && <Button to={`/${placeId}/addPic/isfromaplace`}>Add</Button>}
          </div>
        </Card>
      </li>
    </React.Fragment>
  )
}

// Es braucht noch eine Unterscheidung zwischen subjectPic und placePic

export default PlaceItem

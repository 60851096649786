import React, { useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Input from "../../shared/components/FormElements/Input"
import Button from "../../shared/components/FormElements/Button"
import Card from "../../shared/components/UIElements/Card"
import ErrorModal from "../../shared/components/UIElements/ErrorModal"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner"
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from "../../shared/util/validators"
import { useForm } from "../../shared/hooks/form-hook"
import { useHttpClient } from "../../shared/hooks/http-hook"
import { AuthContext } from "../../shared/context/auth-context"
import "./AddSubject.css"

const EditSubject = () => {
  const auth = useContext(AuthContext)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [loadedSubject, setLoadedSubject] = useState()
  const subjectId = useParams().subjectId

  const navigate = useNavigate()

  const [formState, inputHandler, setFormData] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    const fetchSubject = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/subjects/${subjectId}`)
        setLoadedSubject(responseData.subject)
        setFormData(
          {
            title: {
              value: responseData.subject.title,
              isValid: true,
            },
            description: {
              value: responseData.subject.description,
              isValid: true,
            },
          },
          true
        )
      } catch (err) {}
    }
    fetchSubject()
  }, [sendRequest, subjectId, setFormData])

  const subjectUpdateSubmitHandler = async (event) => {
    event.preventDefault()
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/subjects/${subjectId}`,
        "PATCH",
        JSON.stringify({
          title: formState.inputs.title.value,
          description: formState.inputs.description.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      )
      navigate(-2) // Navigiert zur vorherigen Seite
      // window.location.reload()
    } catch (err) {}
  }

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    )
  }

  if (!loadedSubject && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find Subject!</h2>
        </Card>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && loadedSubject && (
        <form className="place-form" onSubmit={subjectUpdateSubmitHandler}>
          <Input id="title" element="input" type="text" label="Title" validators={[VALIDATOR_REQUIRE()]} errorText="Please enter a valid title." onInput={inputHandler} initialValue={loadedSubject.title} initialValid={true} />
          <Input
            id="description"
            element="textarea"
            label="Description"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter a valid description (min. 5 characters)."
            onInput={inputHandler}
            initialValue={loadedSubject.description}
            initialValid={true}
          />
          <Button type="submit" disabled={!formState.isValid}>
            Update Subject
          </Button>
        </form>
      )}
    </React.Fragment>
  )
}

export default EditSubject

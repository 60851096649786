import React, { useContext } from "react"

import SubjectItem from "./SubjectItem"
import Card from "../../shared/components/UIElements/Card"
import "./SubjectsList.css"
import { AuthContext } from "../../shared/context/auth-context"

const SubjectsList = (props) => {
  const auth = useContext(AuthContext)

  // Rolle des Benutzers
  const userRole = auth.role || "nobody"
  console.log("userRole: ", userRole)
  // Gruppen und ihre Zuordnungen zu Rollen
  const groups = {
    admin28: ["jahre", "reisen", "kanadaplanung", "eckhard", "fahrrad", "galerien", "kanusa", "belize"],
    cordula: ["jahre", "reisen", "kanadaplanung", "eckhard", "fahrrad", "galerien", "kanusa", "belize"],
    inga: ["jahre", "reisen", "kanadaplanung", "eckhard", "fahrrad", "galerien", "kanusa", "belize"],
    martin: ["kanusa", "belize", "fahrrad"],
    reisen: ["reisen"],
    kanusa: ["kanusa", "belize"],
    gast: ["galerien"],
    newbie: ["galerien"],
    nobody: ["nobody"],
  }
  // Hier gibt es die Fehlermeldung, dass includes undefined ist, wenn die Role nicht vorhanden ist:
  // Beispiel: im Datensatz war die Rolle kanada hinterlegt, diese taucht aber in groups nicht auf.
  // Funktion zum Prüfen, ob der Benutzer Zugriff auf eine Gruppe hat
  function hasAccessToGroup(group) {
    const allowedGroups = groups[userRole]
    console.log("userRole:", userRole)
    console.log("allowedGroups:", allowedGroups)
    return allowedGroups.includes(group)
  }

  let subjectsAccess = 0
  props.items.forEach((subject) => {
    if (hasAccessToGroup(subject.group)) {
      subjectsAccess++
    }
  })

  if (subjectsAccess === 0) {
    return (
      <div className="center">
        <Card className="subject-list-item__content">
          <h1>No topics found: Please log in or inform EckiHag to make subject-groups accessible for you.</h1>
        </Card>
      </div>
    )
  }

  return (
    <ul className="subjects-list">
      {props.items.map(
        (subject) => hasAccessToGroup(subject.group) && <SubjectItem key={subject.id} id={subject.id} image={subject.image} title={subject.title} description={subject.description} placeCount={subject.places.length} picsCount={subject.pics.length} />
      )}
    </ul>
  )
}

export default SubjectsList

import React from "react"
import "./EcksGallery.css"
import { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"

function EcksGallery(props) {
  const id = useParams().id
  const [pics, setPics] = useState([])
  const [selectedPic, setSelectedPic] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/pics/bySubjectOrPlaceId/${id}`)
      // Filtere die Bilder, die die Endung ".mp4" haben, aus
      const filteredPics = response.data.pics.filter((pic) => !pic.filename.image.endsWith(".mp4"))
      setPics(filteredPics)
    }

    fetchData()
  }, [id])

  const openModal = (pic) => {
    setSelectedPic(pic)
  }

  const closeModal = () => {
    setSelectedPic(null)
  }

  return (
    <React.Fragment>
      <div className="eck-photo-grid">
        {pics.map((pic, i) => (
          <div key={`img${i + 1}`} className={`eck-photo-item ${i % 5 === 4 ? "v-stretch" : ""} ${i % 9 === 8 ? "h-stretch" : ""}`}>
            <img src={`${process.env.REACT_APP_ASSET_URL}/${pic.filename.image}`} alt={`img${i + 1}`} onClick={() => openModal(pic)} />
          </div>
        ))}
      </div>
      {selectedPic && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img src={`${process.env.REACT_APP_ASSET_URL}/${selectedPic.filename.image}`} alt={selectedPic.filename.image} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default EcksGallery

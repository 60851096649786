import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import Card from "../../shared/components/UIElements/Card"
import PicItem from "../components/PicItem"
import "./ListPicItemsWithText.css"

function ListPicItemsWithText() {
  const id = useParams().id
  const [pics, setPics] = useState([])

  // router.get("/isfromasubject/:source/:id/:picfilename", picsControllers.deletePic)
  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/pics/bySubjectOrPlaceId/${id}`)
      setPics(response.data.pics)
    }
    fetchData()
  }, [id])

  // console.log("picsFetchedBySubjectId: ", pics)

  if (pics.length === 0) {
    return (
      <div className="place-list center">
        <Card>
          <h2>No Pics found.</h2>
        </Card>
      </div>
    )
  }

  return (
    <ul className="pic-list">
      {pics.map((pic) => (
        <PicItem
          key={pic.filename._id}
          image={pic.filename.image}
          belongstoid={pic.filename.belongstoid}
          belongstosource={pic.filename.belongstosource}
          picid={pic.filename._id}
          title={pic.filename.title}
          description={pic.filename.description}
          ord={pic.filename.ord}
          video={pic.filename.video}
        />
      ))}
    </ul>
  )
}

export default ListPicItemsWithText

// The FileUploadJs are from:
// https://www.filestack.com/fileschool/react/react-file-upload/

import "./PlaceForm.css"
import React, { useContext, useState } from "react"

import { AuthContext } from "../../shared/context/auth-context"

import axios from "axios"

function FileUploadVideo() {
  const auth = useContext(AuthContext)
  const [file, setFile] = useState()

  function handleChange(event) {
    setFile(event.target.files[0])
    console.log("file: ", file)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const url = "http://localhost:5000/api/pics/video"
    const formData = new FormData()
    formData.append("file", file)
    formData.append("fileName", file.name)
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + auth.token,
      },
    }

    try {
      const response = await axios.post(url, formData, config)
      console.log(response.data)
    } catch (error) {
      console.error("Fehler beim Hochladen: ", error)
    }
  }

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <h1>React video Upload</h1>
        <input type="file" onChange={handleChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  )
}

export default FileUploadVideo

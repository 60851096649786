import React from "react"
import "./Startseite.css"

const Startseite = () => {
  return (
    <div className="startseite">
      <div className="startseite-content">
        <h1>Please go to AUTHENTICATE - SIGNUP.</h1>
        <h2>Authenticate = Login / Signup = Neuer User</h2>
        <h3>EckiHag wird automatisch über das signup informiert und gewährt ggf. Zugriff auf weitere Inhalte.</h3>
      </div>
    </div>
  )
}

export default Startseite

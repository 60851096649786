import React from "react"

import Card from "../../shared/components/UIElements/Card"
import PlaceItem from "./PlaceItem"
import Button from "../../shared/components/FormElements/Button"
import "./PlaceList.css"

const PlaceList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="place-list center">
        <Card>
          <h2>No places found. Maybe create one?</h2>
          <Button to="/places/new">Share Place</Button>
        </Card>
      </div>
    )
  }
  console.log("PlaceList props.items: ", props.items)
  return (
    <ul className="place-list">
      {props.items.map((place) => (
        <PlaceItem
          key={place.id}
          placeId={place.id}
          image={place.image}
          title={place.title}
          description={place.description}
          address={place.address}
          creatorId={place.creator}
          subjectId={place.creatorsubject}
          coordinates={place.location}
          picsCount={place.pics.length}
          onDelete={props.onDeletePlace}
        />
      ))}
    </ul>
  )
}

export default PlaceList

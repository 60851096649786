import React, { useReducer, useEffect } from "react"

import { validate } from "../../util/validators"
import "./Input.css"

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state, // der Spread-Operator kopiert alle Elemente aus state und überschreibt dann value und isvalid
        value: action.val,
        isValid: validate(action.val, action.validators),
      }
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      }
    }
    default:
      return state
  }
}

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  })

  const { id, onInput } = props
  const { value, isValid } = inputState

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, value, isValid, onInput])

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    })
  }

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    })
  }

  const element =
    props.element === "input" ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler} // onBlur is triggered, when the user looses focus on the input-element / after looses focus to a field, the validation should be triggert / ansonsten wird sofort bei focus im Feld ein Fehler angezeigt / to blur = verschwimmen, verwischen
        value={inputState.value}
      />
    ) : (
      <textarea id={props.id} rows={props.rows || 3} onChange={changeHandler} onBlur={touchHandler} value={inputState.value} />
    )

  return (
    <div className={`form-control ${!inputState.isValid && inputState.isTouched && "form-control--invalid"}`}>
      <label htmlFor={props.id}>{props.label}</label>
      {element}
      {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    </div>
  )
}

export default Input
